
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/greatesthits/[locale]/closed",
      function () {
        return require("private-next-pages/greatesthits/[locale]/closed.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/greatesthits/[locale]/closed"])
      });
    }
  