export default {
  container: {
    backgroundColor: 'background',
    py: '50px',
    px: '50px',
    width: ['400px', '100vw'],
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  heading: {
    style: 'largeBold',
    fontSize: ['20px','40px', '60px'],
    color: 'primary',
  },
}
