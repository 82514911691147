import React from 'react'
import Box from '../../Commons/Box'
import Text from '../../Commons/Text'
import styles from './styles'

const Closed = ({ pageProps }) => {
  const { layout } = pageProps

  return (
    <Box sx={styles.container}>
      <Text styles={styles.heading}>{layout.message}</Text>
    </Box>
  )
}

export default Closed
